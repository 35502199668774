import Bed from '~/assets/svg/newSvg/aside/bed.svg?react';
import Users from '~/assets/svg/newSvg/aside/users.svg?react';
import Kitchen from '~/assets/svg/newSvg/aside/kitchen.svg?react';
import Tools from '~/assets/svg/newSvg/aside/tools.svg?react';
import Chat from '~/assets/svg/newSvg/aside/chat.svg?react';
import Lightning from '~/assets/svg/newSvg/aside/lightning.svg?react';
import Notification from '~/assets/svg/newSvg/aside/notification.svg?react';
import Qr from '~/assets/svg/newSvg/aside/qr.svg?react';
import Transactions from '~/assets/svg/newSvg/aside/transactions.svg?react';
import Case from '~/assets/svg/newSvg/aside/case.svg?react';
import Questionnaire from '~/assets/svg/newSvg/aside/questionnaires.svg?react';
import Settings from '~/assets/svg/newSvg/aside/setting.svg?react';
import Events from '~/assets/svg/events/events.svg?react';
import Entertainment from '~/assets/svg/newSvg/aside/entertainment.svg?react';

export const asideBarList = [
  {
    id: 1,
    path: '/assets',
    text: 'assets',
    type: 'assets',
    sectionId: 0,
    icon: <Case />,
  },
  {
    id: 2,
    path: '/accommodations',
    text: 'accommodations',
    type: 'accommodation',
    sectionId: 1,
    icon: <Bed />,
  },
  {
    id: 3,
    path: '/food',
    text: 'nutrition',
    type: 'food',
    sectionId: 2,
    icon: <Kitchen />,
  },
  {
    id: 4,
    path: '/transactions',
    text: 'orders',
    type: 'orders',
    sectionId: 3,
    icon: <Transactions />,
  },
  {
    id: 5,
    path: '/user',
    text: 'employees',
    type: 'employees',
    sectionId: 4,
    icon: <Users />,
  },
  {
    id: 6,
    path: '/requests',
    text: 'applications',
    type: 'requests',
    sectionId: 5,
    icon: <Tools />,
  },
  {
    id: 7,
    path: '/feedback',
    text: 'feedback',
    type: 'feedback',
    sectionId: 6,
    icon: <Chat />,
  },
  {
    id: 8,
    path: '/announcements',
    text: 'announcements',
    type: 'announcements',
    sectionId: 7,
    icon: <Lightning />,
  },
  {
    id: 9,
    path: '/questionnaires',
    text: 'questionnaires',
    type: 'questionnaires',
    sectionId: 8,
    icon: <Questionnaire />,
  },
  {
    id: 10,
    path: '/events',
    text: 'events',
    type: 'events',
    sectionId: 9,
    icon: <Events />,
  },
  {
    id: 11,
    path: '/entertainment',
    text: 'entertainment',
    type: 'entertainment',
    sectionId: 14,
    icon: <Entertainment />,
  },
];

export const asideBarListSetting = [
  {
    id: 0,
    path: '/notifications',
    text: 'notifications',
    type: 'notifications',
    sectionId: 10,
    icon: <Notification />,
  },
  {
    id: 1,
    path: '/settings',
    text: 'settings',
    type: 'notifications',
    sectionId: null,
    icon: <Settings />,
  },
  {
    id: 2,
    path: '/qr',
    text: 'qr',
    type: 'qr generator',
    sectionId: 11,
    icon: <Qr />,
  },
];
