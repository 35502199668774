import { InputBar, InputMaskBar, InputPassword, QuerySelect } from '~/ui/index';
import { masksphone } from '~/constants/masks';
import styles from './UserForm.module.scss';
import { FormikValues } from 'formik';
import { IAsset } from '~/pages/Assets/Assets';
import { useTranslation } from 'react-i18next';
import { getFoodPoints } from '~/utils/api/queries';
import { instance } from '~/utils/api/api';
import { PaginatedResponse } from '~/utils/types/common';
import CustomSelect, { IOption } from '../Shared/CustomSelect/CustomSelect';
import { getTimezones } from './timezones';
import CustomCheckbox from '../Shared/CustomCheckbox/CustomCheckbox';
import { useEffect } from 'react';

interface Props {
  assets: IAsset[];
  roles: { id: number; name: string }[];
  grades: any;
  sections: { id: number; name: string; type: string }[];
  companyName: string | null | undefined;
  disabledPassword: boolean;
  setPasswordValid: (arg: boolean) => void;
  setDisabledPassword: (arg: boolean) => void;
  formik: FormikValues;
  isAssetSelectShown: boolean;
  isRoleEditable?: boolean;
  isUserCurrent?: boolean;
  timezonesList: { id: string; name: string }[];
  roleName: string;
  isLoading?: boolean;
  isFoodSelectShown?: boolean;
}

export const GeneralFormInfo = ({
  isLoading,
  roleName,
  assets,
  roles,
  grades,
  sections,
  disabledPassword,
  setPasswordValid,
  setDisabledPassword,
  formik,
  isAssetSelectShown,
  isUserCurrent,
  isFoodSelectShown,
}: Props) => {
  const { t } = useTranslation();

  const getFoodPointById = async (id: number): Promise<any> => {
    const response = await instance.get(`food-admin/${id}`);
    return response.data;
  };

  const handleChangeRole = (value: IOption | IOption[]) => {
    formik.setFieldValue('role', value);
  };

  const timezones = getTimezones();

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: '',
    page: 1,
  };

  useEffect(() => {
    if (formik.values.role.id === 3 || formik.values.role.id === 1) {
      formik.setFieldValue('sections', []);
    }
    return;
  }, [formik.values.role]);
  return (
    <div className={styles.formItems}>
      <InputBar
        name="uniqueId"
        value={formik.values.uniqueId}
        disabled={isLoading}
        label={t('unique_user_ID')}
        star={true}
        placeholder={t('unique_user_ID')}
        onChange={formik.handleChange}
        classNameInput={styles.labelUser}
      />
      <div className={styles.userStatus}>
        {grades && (
          <CustomSelect
            label={t('user_type')}
            options={grades}
            value={formik.values.grade}
            onChange={value => {
              formik.setFieldValue('grade', value);
            }}
            required
            placeholder={t('validate_user_type')}
            disabled={isLoading}
            error={
              formik.touched.grade ? (formik.errors.grade as string) : undefined
            }
            onTouch={() => formik.setFieldTouched('user_type', true)}
          />
        )}

        {isUserCurrent ? (
          <InputBar
            label={t('user_role')}
            value={roleName}
            onChange={() => {}}
            disabled
          />
        ) : roles ? (
          <CustomSelect
            label={t('user_role')}
            options={roles}
            value={formik.values.role}
            onChange={value => {
              handleChangeRole(value);
            }}
            disabled={roles.length < 2 || isUserCurrent || isLoading}
            placeholder={t('validate_user_role')}
            required
            error={
              formik.touched.role ? (formik.errors.role as string) : undefined
            }
            onTouch={() => formik.setFieldTouched('user_role', true)}
          />
        ) : (
          ''
        )}
        {isAssetSelectShown ? (
          <CustomSelect
            label={t('active')}
            value={formik.values.assets}
            onChange={value => {
              formik.setFieldValue('assets', value);
            }}
            options={assets}
            required
            placeholder={t('validate_asset')}
            disabled={isLoading}
            error={formik.errors.asset}
          />
        ) : isFoodSelectShown ? (
          <QuerySelect
            onChange={value => {
              formik.setFieldValue('food_id', value);
            }}
            value={formik.values.food_id}
            queryFn={({
              pageParam = 1,
              meta,
            }): Promise<PaginatedResponse<any>> =>
              getFoodPoints({
                sort: '',
                perPage: 100,
                field: '',
                query: meta?.searchQuery as any,
                page: pageParam,
              })
            }
            queryKey={['food', params]}
            getItems={getFoodPointById}
            star={true}
            className={styles.foodWaresSelect}
            label={t('food_object')}
            error={formik.errors.foodPoint}
          />
        ) : (
          <InputBar
            name="company_name"
            value={formik.values.company_name}
            disabled={isLoading}
            label={t('company_name')}
            star={true}
            placeholder={t('company_name')}
            onChange={formik.handleChange}
            errors={formik.errors.company_name}
            onBlur={formik.handleBlur}
            error={
              formik.touched.company_name && Boolean(formik.errors.company_name)
            }
          />
        )}
      </div>

      <div className={styles.inputsWrapperTop}>
        <InputBar
          name="name"
          value={formik.values.name}
          disabled={isLoading}
          label={t('first_name')}
          star={true}
          placeholder={t('text')}
          onChange={formik.handleChange}
          errors={formik.errors.name}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />

        <InputBar
          name="patronymic"
          value={formik.values.patronymic}
          disabled={isLoading}
          label={t('patronymic')}
          star={false}
          placeholder={t('text')}
          onChange={formik.handleChange}
          errors={formik.errors.patronymic}
          onBlur={formik.handleBlur}
          error={formik.touched.patronymic && Boolean(formik.errors.patronymic)}
        />

        {timezones && (
          <CustomSelect
            label={t('time_zone')}
            placeholder={t('validate_time_zone')}
            options={timezones}
            value={formik.values.timezone}
            onChange={value => {
              formik.setFieldValue('timezone', value);
            }}
            disabled={isLoading}
          />
        )}
      </div>

      <div className={styles.inputsWrapperBottom}>
        <InputMaskBar
          type="phone"
          placeholder="+7 XXX XXX XX XX"
          value={formik.values.phone}
          star={true}
          label={t('phone_number')}
          name="phone"
          mask={masksphone}
          errors={formik.errors.phone}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          onChange={formik?.handleChange}
          disabled={isLoading}
        />

        <InputBar
          name="email"
          value={formik.values.email}
          disabled={isLoading}
          label={t('mailbox')}
          star={true}
          placeholder={t('text')}
          onChange={formik.handleChange}
          errors={formik.errors.email}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />

        <InputPassword
          star={true}
          value={formik.values.password}
          label={t('password')}
          name="password"
          placeholder="***********"
          onChange={formik.handleChange}
          disabled={disabledPassword || !!isLoading}
          errors={formik.errors.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          setPasswordValid={setPasswordValid}
          onClick={() => {
            formik.setFieldValue('password', '');
            setDisabledPassword(false);
          }}
        />
      </div>
      <div>
        <CustomSelect
          label={`${t('user_sections')}:`}
          placeholder={t('choose')}
          options={sections}
          value={formik.values.sections}
          onChange={value => {
            formik.setFieldValue('sections', value);
          }}
          disabled={
            isLoading ||
            formik.values.role.id === 1 ||
            formik.values.role.id === 3
          }
          required
          multiselect
          error={
            formik.touched.sections
              ? (formik.errors.assets as string)
              : undefined
          }
          onTouch={() => formik.setFieldTouched('sections', true)}
        />
      </div>
      <div className={styles.noviceWrapper}>
        <label className={styles.noviceLabel}>
          <CustomCheckbox
            isChecked={formik.values.is_novice}
            onChange={() =>
              formik.setFieldValue('is_novice', !formik.values.is_novice)
            }
          />
          {t('first_labor')}
        </label>
      </div>
    </div>
  );
};
