import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import MobileHeading from '~/components/MobileHeading/MobileHeading';
import { instance } from '~/utils/api/api';
import styles from './ArticlePage.module.scss';
import { useToastError } from '~/utils/useToastError';
import SpinnerCircle from '~/ui/SpinnerCircle/SpinnerCircle';

const ArticlePage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { id } = useParams();
  const toastError = useToastError();

  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        `admin/knowledge-base-articles/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    },
    queryKey: ['knowledge-articles', id],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <main className={styles.mainContainer}>
      <div className={styles.row} />
      {!isLoading ? (
        <div className={styles.contentWrapper}>
          <MobileHeading title={data.title} />
          <div className={styles.content}>
            <img
              src={data.main_photo_url[0].full_photo_url}
              className={styles.image}
            />
            <div
              className={styles.contentDescription}
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
        </div>
      ) : (
        <SpinnerCircle />
      )}
    </main>
  );
};

export default ArticlePage;
